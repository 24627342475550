<template>
  <WebccModal>
    <UploadAvatar :desktop="true" :mobile="false" @close="closeModal" />
  </WebccModal>
</template>

<script lang="ts" setup>
const emit = defineEmits(['close'])

function closeModal() {
  emit('close')
}
</script>
