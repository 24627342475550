<template>
  <div
    class="relative h-fit grow-0 border border-bgr-300 shadow-lg rounded flex px-4 py-3 md:p-5 lg:p-6 items-center gap-4"
  >
    <!-- Avatar -->
    <div class="relative">
      <div
        class="shrink-0 size-12 sm:size-14 md:size-16 xl:size-20 rounded-full overflow-hidden bg-thm flex items-center justify-center"
      >
        <img
          v-if="userData?.profileImg"
          :src="'data:image/png;base64,' + userData?.profileImg"
          alt=""
        />
        <span
          v-else-if="initials !== ''"
          class="text-lg lg:text-2xl text-bgr"
          >{{ initials }}</span
        >
        <WebccIcon
          v-else
          name="account/user"
          class="size-6 sm:size-8 text-bgr"
        />
      </div>
      <!-- Upload Button -->
      <UploadAvatarButton
        :profile-img="userData?.profileImg"
        @remove-avatar="onRemoveAvatar"
        @upload-modal="onUploadAvatar"
      />
    </div>
    <!-- Name -->
    <div class="overflow-hidden">
      <span class="text-base sm:text-xl md:text-2xl" :title="displayedName">{{
        displayedName
      }}</span>
      <div class="flex items-center text-txt-weak">
        <WebccIcon
          name="account/mail"
          class="size-4 text-txt-weak shrink-0 mr-2 hidden sm:flex"
        />
        <p
          class="text-sm sm:text-base truncate"
          :title="userData?.contacts.email"
        >
          {{ userData?.contacts.email }}
        </p>
      </div>
    </div>
    <!-- Upload avatar modal -->
    <UploadAvatarModal v-if="uploadAvatar" @close="uploadAvatar = false" />
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'guestWorld.components.myaccount.profile.UserCard.'
const props = defineProps<{
  desktop: boolean
  mobile: boolean
}>()
const emit = defineEmits(['uploadAvatarMobile', 'removeAvatar'])

const { userData } = storeToRefs(useUser())

const { t } = useI18n()

const displayedName = computed(() =>
  useUser().name ? useUser().name : t(`${trPrefix}user`),
)

const uploadAvatar = ref(false)

// computes the first letters of the first and last name and combines it to the initials
const initials = computed(() => {
  if (userData.value?.contacts.firstName && userData.value?.contacts.lastName) {
    return `${userData.value.contacts.firstName[0]}${userData.value.contacts.lastName[0]}`
  }
  return ''
})

function onUploadAvatar() {
  if (props.desktop) {
    uploadAvatar.value = true
  } else {
    emit('uploadAvatarMobile')
  }
}

function onRemoveAvatar() {
  emit('removeAvatar')
}
</script>

<style scoped></style>
